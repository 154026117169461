@import "variables";
@import "mixins";

div.modal.modal-fixed-footer.investment-modal.investment-modal {
    height: 300px;
    max-width: 450px;
}

iframe.quickly-sign {
    width: 100%;
    height: 750px;
}


.tabs-navigation {
    margin: 1em auto;
    max-width: 685px;
    text-align: center;
    a.back {
        font-size: $small-text;
        float: left;
        margin-left: 1em;
        padding: 1em;
        img {
            vertical-align: middle;
            margin-top: -3px;
        }
    }
    ul {
        @include box-shadow-custom(0 0 2px 0 rgba(0, 0, 0, .5));
        overflow: hidden;
        display: inline-block;
        margin: 0;
        @include border-radius(6px);
        li {
            display: inline-block;
            float: left;
            a {
                @include transition(0.4s);
                font-size: $small-text;
                display: block;
                background-color: white;
                padding: 0.9em 1.2em;
                text-decoration: none;
                border-right: 1px solid #CBC5D2;
            }
            a:hover {
                background-color: #EEEEEE;
            }
            a.active {
                @include vert-gradient($primary-color, #7A53AD, #4F2683);
                color: white;
            }
            a.inactive {
                background-color: #FAFAFA;
                color: #a9a9a9;
            }
        }
        li:first-of-type a {
            @include border-radius(6px 0 0 6px);
        }
        li:last-child a {
            @include border-radius(0 6px 6px 0);
            border-right: 0;
        }
    }
}

.investment-options-collection {
    margin: 0;
    .investment-option-item {
        @include border-radius(4px);
        border: 1px solid $med-grey-color;
        padding: 0.8em;
        margin-bottom: 1em;
        &.selected-option {
            border-color: $primary-color;
            background-color: #F5F5F5;
        }
        &:last-child {
            margin-bottom: 0;
        }
        .header {
            font-weight: bold;
            font-size: 1.45em;
        }
        .investment-option-details {
            padding-left: 2.3em;
            margin-top: 0.7em;
        }
        .amount-group {
            margin-top: 1.5em;
            .investment-amt-parent {
                position: relative;
                margin-top: 0.3em;
                span {
                    @include border-radius(3px 0 0 3px);
                    position: absolute;
                    left: 1px;
                    top: 1px;
                    bottom: 1px;
                    background: #DDDDDD;
                    color: #6A6375;
                    padding: 0.45em 0.9em;
                }
                input {
                    margin: 0;
                    padding-left: 50px;
                    width: auto;
                    background-color: white;
                }
            }
        }
    }

}

.invest-page {
    padding-bottom: 5rem;
    .modal {
        a.resend-email-btn,
        .email-sent {
            @include resend-email-btn;
            font-size: initial;
            display: block;
            width: 225px;
            text-align: center;
            margin: 1.5em auto 0 auto;
            img {
                height: 20px;
            }
        }
        .email-sent {
            color: $success-color;
            font-weight: 600;
            font-size: initial;
            img {
                height: 15px;
                margin: -2px 4px 0 0;
                vertical-align: middle;
            }
        }
    }
    h2.page-title {
        margin: 0.6em 0 0.8em 0;
    }
    .card .card-content {
        .fetching-doc-message {
            margin: 3.5rem 0;
            img {
                margin-bottom: .5rem;
            }
        }
        .doc-signed-message {
            color: $success-color;
            font-size: 1.5rem;
            margin: 3.5rem 0;
            img {
                width: 26px;
                vertical-align: middle;
                margin: -6px 9px 0 0;
            }
        }
        h4 {
            margin-bottom: 1em;
        }

        p.upload-parent {
            margin: -10px 0 -5px 0;
            &.passport {
                margin-top: 0;
            }
        }
        .input-field {
            max-width: 400px;
        }
    }

    &.agreement-doc .buttons-group {
        bottom: 0;
    }

    &.payment {
        &.container {
            max-width: 900px;
            width: 80%;
        }
        .payment-buttons-group .payment-option-btn {
            position: relative;
            cursor: pointer;
            text-align: center;
            [type="radio"] + label {
                @include opacity(0);
                position: absolute;
            }
            p {
                color: #CCCCCC;
            }
            p.selected {
                color: $primary-color;
            }
            img {
                max-width: 100%;
            }
        }
        .payment-option-details {
            margin-top: 2rem;
        }
    }
}

.fixed-summary-footer {
    background-color: $card-header-dark-background-color;
    color: white;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1002;
    height: $fixed-summary-footer-height;
    padding: 5px 15px;
    h3.amt-raised {
        margin: 0;
        line-height: 2em;
    }
    p {
        font-size: $small-text;
        margin: 0;
        text-align: right;
    }
}

.pitch-details-side-parent {
    hr.perforated {
        border-top: 1px dashed #B3B3B3;
        position: relative;
        overflow: visible;
        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -10px;
            width: 10px;
            height: 20px;
            background-color: #F2F2F2;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #DCDCDC;
        }
        &:before {
            @include border-radius(0 10px 10px 0);
            left: -1.65em;
            border-left: 0;
        }
        &:after {
            @include border-radius(10px 0 0 10px);
            right: -1.65em;
            border-right: 0;
        }
    }
    .card-header.dark-card-header {
        height: 48px;
        padding-top: 0.3em;
        p {
            color: rgba(255, 255, 255, 0.75);
            margin: 0 0 -3px 0;
        }
    }
    h3.amt-raised {
        margin: 0;
        line-height: 1.7em;
        text-align: right;
    }
    .share-image {
        @include border-radius(50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 80px;
        height: 80px;
        float: right;
    }
    .share-type-row {
        margin-bottom: 0.5em;
        .share-type-name {
            margin-top: -2px;
        }
    }
    .share-type-description {
        line-height: 1.2em;
        margin-top: 1px;
    }

    .more-about-share-types {
        @include border-radius(6px);
        background-color: rgba(255, 255, 255, 0.7);
        padding: $card-padding;
        font-weight: 200;
        line-height: 1.3rem;
        .attachment.share-type-info-docs {
            margin-top: 1.5em;
            margin-bottom: 0;
            a {
                font-size: $small-text;
            }
        }
    }
    /*  /more-about-share-types */

}

@media only screen and (max-width: 1300px) {
    .invest-page.container {
        width: 95%;
    }
    .invest-page.payment.container {
        width: 100%;
        max-width: none;
    }
}

@media only screen and (max-width: 992px) {
    .investment-options-collection .investment-option-item .investment-option-details {
        padding-left: 0;
    }
    .pitch-details-side-parent {
        text-align: center;
        hr.perforated:after {
            right: -1.5em;
        }
        hr.perforated:before {
            left: -1.5em;
        }
        .card .card-content {
            overflow: visible;
        }
        .right-align {
            text-align: center;
        }
        .total-amount-label-row {
            text-align: center;
        }
        .card-header.dark-card-header {
            height: auto;
        }
        h3.amt-raised {
            text-align: center;
            margin-bottom: -0.3em;
        }
        .amt-label {
            display: inline-block;
        }
        .share-image {
            float: none;
            margin: 0 auto 0.5em auto;
        }
    }
}

@media only screen and (max-width: 768px) {
    .pitch-details-side-parent {
        hr.perforated:after {
            right: -1.1em;
        }
        hr.perforated:before {
            left: -1.1em;
        }
    }
    .pitch-details-side-parent .more-about-share-types {
        padding: $card-padding-mobile;
    }
    .pitch-option-name,
    .pitch-option-days-remaining,
    .share-type-name {
        font-size: $small-text;
    }

    .tabs-navigation {
        margin: 0.7em auto;
        text-align: center;
        ul {
            @include box-shadow-custom(none);
        }
        a.back {
            display: none;
        }
        ul li a {
            padding: 0.7em 0.8em;
            border: 1px solid #CBC5D2;
            border-left: 0;
        }
        ul li:last-child a {
            border-right: 1px solid #CBC5D2;
        }
    }
}

@media only screen and (max-width: 600px) {
    .tabs-navigation {
        display: none;
    }
    .invest-page .buttons-group {
        @include tile-box-shadow;
        font-size: $small-text;
        padding: 5px 12px 5px 0;
        z-index: 1001;
        position: fixed;
        left: 0;
        right: 0;
        bottom: $fixed-summary-footer-height;
        background-color: white;
        .back-arrow {
            padding: 0.7em 1em;
            img {
                vertical-align: middle;
            }
        }
        .cta {
            display: inline-block;
            margin-top: 2px;
            vertical-align: middle;
        }
        .btn {
            padding: 0 1.2rem;
            line-height: 40px;
            height: 41px;
        }
    }
}