@import "variables";
@import "mixins";
@import "home";
@import "auth";
@import "admin";
@import "pitch";
@import "user";
@import "invest";
@import "static_pages";
@import "discover";

body {
  font-family: 'Open Sans', sans-serif;
  color: $body-text-color;
  font-size: 16px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
}

header {
  z-index: 10;
}

main {
  position: relative;
  flex: 1 0 auto;
}

nav ul a {
  text-decoration: none;
}

nav {
  .spacer-for-mobile {
    display: none;
  }
}

h1, h2, h3, h4 {
  margin: 0;
  line-height: initial;
}

h1 {
  font-size: 2.8em;
}

h1.super-large {
  font-size: 3.4em;
  font-weight: bold;
  line-height: 1em;
  margin-bottom: 0.3em;
}

h2 {
  font-size: 2em;
  a {
    color: #582C83;
    font-weight: bold;
  }
  &.page-title {
    text-align: center;
    margin: 1.2em 0;
  }
}

.hide-on-mobile {
  display: block;
}

.show-on-mobile {
  display: none;
}

.title-description {
  text-align: center;
  margin-top: 0;
  line-height: 1.4em;
}

h2.page-title + .title-description {
  margin-top: -2.2em;
  margin-bottom: 2em;
}

h2.page-title + .title-description-no-margin-bot {
  margin-top: -2.2em;
  text-align: center;
}

h3 {
  font-size: 1.45em;
  margin: 1.9em 0 1.2em 0;
}

h4 {
  font-size: 1.2em;
  font-weight: bold;
}

h4.uprise-title {
  color: #30243E;
  margin: 1.8rem 0;
  font-size: 30px;
  text-align: center;
}

strong {
  font-weight: bold;
}

textarea.materialize-textarea {
  padding: .5em .8em;
  width: calc(100% - 1.6em);
  margin: .3em auto auto auto;
  border: 1px solid #8F8F8F;
  border-radius: 4px;
  background-color: #F8F8F8;
  min-height: 4rem;
}

select,
input[type=text],
input[type=number],
input[type=password],
input[type=email],
input[type=tel] {
  padding-left: 0.8em;
  padding-right: 0.8em;
  padding-top: -1px !important;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  border: 1px solid #8F8F8F;
  border-radius: 4px;
  background-color: #F8F8F8;
  height: 2.6rem;
  margin-top: 0.3em;
}

select {
  display: block;
}

input:not([type]):focus:not([readonly]),
input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  @include box-shadow-custom(none);
  border: 1px solid $primary-color;
}

.hidden {
  display: none;
}

.input-field {
  margin: 0;
}

[type="radio"] + label:before,
[type="radio"] + label:after {
  margin-left: 0;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: $primary-color;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 2px solid $primary-color;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  font-size: 1em;
}

[type="checkbox"].filled-in:checked + label:after {
  border: 2px solid $primary-color;
  background-color: $primary-color;
}

label {
  font-size: 1em;
  color: $body-text-color;
}

[type="checkbox"] + label {
  font-size: initial;
  line-height: 20px;
}

hr {
  border: 0;
  border-top: 1px solid $med-grey-color;
  margin: 1.5em 0;
}

.btn,
.btn:hover,
.btn-small,
.btn-small:hover,
.btn:focus {
  @include border-radius(24px);
  @include box-shadow-custom(none);
  @include transition(0.4s);
  height: 44px;
  line-height: 42px;
  background-color: $primary-color;
  font-weight: 400;
  border: 1px solid white;
}

.btn:hover,
.btn-small:hover {
  color: white;
  text-decoration: none;
}

.btn-small:hover,
.btn:hover {
  background-color: $primary-color-dark;
}

.btn-small,
.btn-small:hover {
  padding: 0 1.2rem;
  font-size: $small-text;
  line-height: 39px;
  height: 41px;
}

.btn.footer-btn {
  margin-top: 1em;
}

.google-btn,
.facebook-btn {
  font-weight: 400;
  .center-block {
    text-align: left;
    max-width: 252px;
  }
  img {
    vertical-align: middle;
    margin: -3px 0.8em 0 0;
  }
  &:hover {
    font-weight: 400;
  }
}

.signin {
  .google-btn,
  .facebook-btn {
    .center-block {
      max-width: 240px;
    }
  }
}

.google-btn,
.google-btn:hover {
  background-color: white;
  color: black;
  padding: 0;
  img {
    width: 23px;
  }
}

.facebook-btn,
.facebook-btn:focus {
  background-color: #34599D;
  margin: 0.5em 0 0 0;
  &:hover {
    background-color: #2A4C8C;
  }
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-floating) {
  text-transform: none;
  margin: 0;
  color: #582C83;
  &:hover {
    color: #582C83;
  }
}

a {
  color: #582C83;
  cursor: pointer;
  &:hover {
    color: #582C83;
    text-decoration: underline;
  }
}

a.cta {
  color: $primary-color;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

a.cta-small {
  font-size: $small-text;
}

a.view-article-cta {
  color: #4F2683;
  font-weight: bold;
  text-decoration: underline;
  font-size: 13px;
}

.uprise-banner {
  background-color: white;
  ul {
    li {
      a {
        color: #000000;
        font-size: 16px;
        font-weight: 600;
        float: right;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.bg.bg-loaded {
  background-size: cover !important;
}

.uprise-logo {
  width: 184px;
  margin: 17px 23px;
}

.uprise-logo.mobile {
  width: 35px;
  height: auto;
  margin: 7px 10px 5px 10px;
  display: none;
}

.modal {
  @include border-radius(6px);
  .modal-close {
    position: absolute;
    right: 10px;
    top: 14px;
    font-size: $small-text;
  }
  .modal-header {
    background-color: $card-header-background-color;
    border-bottom: 1px solid $card-header-border-color;
    padding: .7em $card-padding;
  }
  .modal-content {
    padding: $card-padding;
  }
}

.email-verified-view {
  margin: 3em 1em;
  h2 {
    color: $success-color;
    img {
      margin: -7px 10px 0 0;
      vertical-align: middle
    }
  }
  h4 {
    font-weight: normal;
    margin-top: 1em;
  }
}

/* General/misc styles start */
.error {
  font-size: 0.9em;
  color: $error-color;
  font-weight: bold;
}

.hr-with-text {
  text-align: center;
  position: relative;
  margin: 2em 0 1.7em 0;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -1px;
    height: 1px;
    background-color: $med-grey-color;
  }
  p {
    width: 50px;
    background-color: white;
    margin: 0 auto !important;
    font-size: $small-text;
    position: relative;
    z-index: 1000;
  }
}

.fake-cta {
  color: $primary-color;
  font-weight: bold;
}

.transition {
  @include transition(1s);
}

.no-margin {
  margin: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-padding-bot,
.card .card-content.no-padding-bot {
  padding-bottom: 0;
}

.background-image {
  @include opacity(0.6);
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: center url("/static/img/background-resized.jpg") no-repeat;
  background-size: cover;
  filter: blur(10px);
  -webkit-filter: blur(10px);
  &.pattern {
    @include opacity(1);
    background: #F2F2F2 url("/static/img/pattern-repeat.svg") repeat;
    background-size: auto;
    filter: blur(0);
    -webkit-filter: blur(0);
  }
}

.full-width {
  width: 100%;
}

.group {
  position: relative;
}

.slanted-edge {
  position: absolute;
  left: 0;
  top: -30px;
  right: 0;
  z-index: -1;
  height: 200px;
  margin-left: -1px;
  background-color: white;
  -webkit-transform: rotate(-1deg);
  -ms-transform: rotate(-1deg);
  transform: rotate(-1deg);
  box-shadow: 0 -5px 3px -3px rgba(93, 110, 128, .1);
}

.slanted-edge.slanted-edge-bottom {
  top: auto;
  bottom: -35px;
  height: 40px;
  box-shadow: none;
  width: 100%;
  border-bottom: 1px solid rgba(235, 235, 235, 1);
  z-index: 100;
}

.oval-logo {
  height: 82px;
  width: 82px;
  background-color: #FFFFFF;
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  img {
    @include centralise;
    max-width: 80%;
    height: auto;
  }
}

a .icon-right {
  vertical-align: middle;
  margin: -2px 0 0 8px;
}

.caption {
  font-size: $small-text;
  line-height: 1.4em;
}

.text-center {
  text-align: center;
}

.text-light {
  font-weight: 300;
}

.text-italic {
  font-style: italic;
}

.card {
  @include border-radius(6px);
  margin-bottom: 1.5em;
  .card-header {
    @include border-radius(6px 6px 0 0);
    background-color: $card-header-background-color;
    border: 1px solid white;
    border-bottom-color: $card-header-border-color;
    padding: 0.6em $card-padding;
    text-align: left;
    position: relative;
    cursor: default;
    &.dark-card-header {
      background-color: $card-header-dark-background-color;
      color: white;
      border: 0;
    }
    .edit-btn {
      color: $primary-color;
      position: absolute;
      right: 15px;
      top: 15px;
      font-weight: bold;
      cursor: pointer;
      img {
        float: left;
        margin: 2px 0.5em 0 0;
      }
    }
  }
  .card-content {
    padding: $card-padding;
    .info-block.at-foot {
      @include border-radius(0 0 6px 6px);
    }
  }
  .card-action {
    @include border-radius(0 0 6px 6px);
  }
  &.card-smallish {
    max-width: $smallish-card-width;
  }
  &.card-small {
    max-width: $small-card-width;
  }
  &:hover {
    cursor: default;
  }
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

a.large-square-button {
  @include border-radius(6px);
  border: 1px solid $med-grey-color;
  background-color: $light-grey-color;
  padding: 1.2em 1em;
  color: $primary-color;
  position: relative;
  display: block;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  min-height: 200px !important;
  p {
    line-height: 1.2em;
  }
  img {
    margin: 0 auto 0.8em auto;
    max-width: 80%;
  }
  &:hover {
    @include box-shadow-custom(0 1px 10px 0 rgba(0, 0, 0, .3));
    @include transition(0.4s);
  }
  &.new-item {
    border: 2px dashed $primary-color;
    background-color: #F6F6F6;
  }
}

.info-block {
  background-color: $info-box-background-colour;
  clear: both;
  margin: 0 -1.7em $card-padding -1.7em;
  position: relative;
  font-size: $small-text;
  text-align: left;
  &.at-foot {
    margin-bottom: 0;
  }
  .inner {
    padding: 1em 1.7em 1em 1.7em;
  }
  .inner.with-shadow {
    padding-bottom: 2.2em;
    max-height: 370px;
    overflow-x: auto;
    &:after {
      @include faded-info-gradient;
      position: absolute;
      content: '';
      bottom: -1px;
      left: 0;
      right: 0;
      height: 50px;
    }
  }
  .info-circle {
    @include border-radius(50%);
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    border: 2.74px solid #E8E8E8;
    background-color: #895e96;
    position: absolute;
    left: -21px;
    top: 15px;
    img {
      @include centralise;
    }
  }
  ul {
    li {
      margin: 0.7em 0 0 1.4em;
      list-style-type: disc;
    }
  }
}

/* General/misc styles END */
.styled-table,
.styled-table thead th,
.styled-table tbody tr,
.styled-table tbody td {
  border-collapse: separate;
}

.attachment {
  a {
    @include border-radius(4px);
    border: 1px solid $primary-color;
    padding: 0.5em 0.8em 0.5em 0.5em;
    text-decoration: none;
    color: $primary-color;
    font-weight: bold;
    display: inline-block;
    clear: both;
    margin-top: 0.5em;
    img {
      margin-right: 0.4em;
      width: auto;
      height: 22px;
      vertical-align: middle;
      margin-top: -3px;
    }
  }
}

.styled-table {
  @include border-radius(6px);
  border: 1px solid $table-border-color !important;
  margin-top: 0.8em;
  td,
  th {
    padding: 0.5em 1em;
  }
  thead th {
    font-size: $small-text;
    background-color: $table-header-color;
    font-weight: normal;
    padding-left: 1.2em;
    padding-right: 1.2em;
    &:first-child {
      @include border-radius(6px 0 0 0);
    }
    &:last-child {
      @include border-radius(0 6px 0 0);
    }
  }
  tbody tr td {
    border-bottom: 1px solid $table-row-border-color;
  }
  tbody tr:last-child td {
    border-bottom: 0;
  }
}

.centralise {
  @include centralise;
}

.buttons-group {
  &.smallish {
    max-width: $smallish-card-width;
  }
  &.right-align {
    .btn,
    .cta {
      margin-left: 1em;
    }
  }
}

.docs-container {
  @include border-radius(4px);
  margin: 1em 0 0 0;
  padding: 0.2em 0;
  padding-bottom: .4em;
  background-color: $light-grey-color;
  .docs-item {
    margin-top: 5px;
    line-height: 1.4em;
    padding: .15em .7em .5em 1em;
    a.delete {
      float: left;
      margin-right: 0.8em;
    }
    img {
      vertical-align: middle;
      margin-top: -4px;
      margin-right: 0.3em;
    }
  }
}

.hidden {
  display: none;
}

.negative-bottom-margin {
  margin-bottom: -1em;
}

.question {
  margin-bottom: 0.5em;
}

.card .card-content .answer,
.answer {
  margin-top: 0.5em;
}

.card .card-content p.correct-answer,
.card .card-content p.incorrect-answer,
.correct-answer,
.incorrect-answer {
  margin: 0 0 0.8em 3px;
  font-size: $small-text;
  display: table;
  & > span {
    display: table-cell;
  }
  & > span:first-of-type {
    width: 32px;
  }
}

.correct-answer, .correct-answer span {
  color: $success-color;
}

.incorrect-answer, .incorrect-answer span {
  color: $error-color;
}

.file-upload-parent {
  min-height: 67px;
  position: relative;
  .progress {
    margin-bottom: 0;
  }

  label.file {
    @include border-radius(6px);
    background-color: #F8F8F8;
    border: 2px solid $primary-color;
    color: #505050;
    cursor: pointer;
    display: block;
    min-height: 57px;
    padding: 10px;
    position: relative;
    left: 0;
    p {
      margin: 5px 0 0 0;
    }
    img {
      margin-right: 15px;
      float: left;
      margin-bottom: 1em;
      width: 26px;
    }
    img#powerpoint {
      margin-right: 10px;
      width: 38px;
    }
  }
  input[type=file] {
    display: none;
  }
  .progress-bar {
    position: absolute;
    left: 10px;
    right: 10px;
    width: auto;
    height: 6px;
    background-color: $light-grey-color;
    display: none;

    .upload_progress {
      width: 0%;
      height: 100%;
      background: $success-color;
      transition: width 0.5s, transform 0.5s;
    }
  }
}

.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon {
  span {
    @include box-shadow-custom(0 3px 10px -5px rgba(0, 0, 0, 1));
    font-size: 12px;
    font-weight: bold;
    color: $body-text-color;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: $pitch-pending-color;
    position: absolute;
    top: 19px;
    left: -21px;
    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border: 3px solid transparent;
      border-left-color: $pitch-pending-dark-color;
      border-top-color: $pitch-pending-dark-color;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border: 3px solid transparent;
      border-right-color: $pitch-pending-dark-color;
      border-top-color: $pitch-pending-dark-color;
    }
  }
  &.live span {
    background: $pitch-live-color;
    color: white;
    &:before {
      border-left-color: $pitch-live-dark-color;
      border-top-color: $pitch-live-dark-color;
    }
    &:after {
      border-right-color: $pitch-live-dark-color;
      border-top-color: $pitch-live-dark-color;
    }
  }
  &.saved span {
    color: white;
    background: $pitch-saved-color;
    &:before {
      border-left-color: $pitch-saved-dark-color;
      border-top-color: $pitch-saved-dark-color;
    }
    &:after {
      border-right-color: $pitch-saved-dark-color;
      border-top-color: $pitch-saved-dark-color;
    }
  }
  &.finished span {
    color: #8B8B8B;
    background: $pitch-finished-color;
    &:before {
      border-left-color: $pitch-finished-dark-color;
      border-top-color: $pitch-finished-dark-color;
    }
    &:after {
      border-right-color: $pitch-finished-dark-color;
      border-top-color: $pitch-finished-dark-color;
    }
  }
}

.pitch-card {
  margin-bottom: 20px;
  a {
    text-decoration: none;
  }
  position: relative;
  .card {
    cursor: pointer;
  }
}

.hide-risk-warning-btn {
  animation-delay: 10s;
  margin-bottom: -42px;
  background-color: #5A575E;
  height: 60px;
  z-index: 1000;
  position: relative;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes delay-show {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

footer.page-footer {
  margin-top: 0;
  background-color: #5A575E;

  a.risk-warning-button {
    @include transition(0.4s);
    animation: delay-show 3s;
    background: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.50);
    border-radius: 23px;
    text-decoration: none;
    padding: 10px 0 10px 16px;
    vertical-align: middle;
    margin: -30px auto 15px auto;
    display: block;
    width: 285px;
    img {
      vertical-align: middle;
      margin: -6px 5px 0 2px;
    }
    &:hover {

      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
    }
  }
  ul {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    li {
      font-weight: normal;
      strong {
        font-weight: 600;
        font-size: 18px;
      }
      a {
        color: #FFFFFF;
        text-decoration: none;
      }
    }
  }
  p {
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
  }
}

.social-media-footer {
  margin-top: 20px;
  p {
    margin: 2px 10px 0 0;
    text-align: left;
    float: left;
    font-size: 13px;
    font-weight: 100;
  }
  img {
    height: 24px;
    margin-left: 3px;
  }
}

.center-spinner {
  margin: 0 auto;
  display: block;
  margin-bottom: 50px;
}

.uprise-preloader {
  margin: 0;
}

.pitch-eft-details {

  div.row {
    padding: 0 0 0 8px;
    margin: 0;

  }

  div.row.top {
    padding-top: 20px;
  }

  p.title {
    font-weight: bold;
    font-size: 17px;
    padding-left: 5px;
  }

  div.col {
    margin: 0;
    padding: 0 10px;
  }

  div.footer {
    color: #888888;
    font-style: inherit;
    padding: 0 20px 20px 16px;
  }

}

.disclaimer-label {
  a {
    color: #582C83;
    font-weight: bold;
  }
}

.sticky-bottom-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 10px;
  @include tile-box-shadow;
}

#google-sign-in {
  .abcRioButton.abcRioButtonLightBlue {
    width: 100% !important;
    height: 44px !important;
    margin-bottom: 5px;
    background-color: white;
    border: 2px solid #4285F4;
    color: black;
    border-radius: 24px;
    font-weight: 400;
  }
  .abcRioButtonIcon {
    padding-left: 100px !important;
    padding-top: 12px !important;
    float: left;
    width: 25px;
  }
  span.abcRioButtonContents {
    line-height: 44px !important;
    float: left !important;
    margin-left: 20px;
  }
}

#google-sign-in-small {
  .abcRioButton.abcRioButtonLightBlue {
    width: 100% !important;
    height: 44px !important;
    margin-bottom: 5px;
    background-color: white;
    border: 2px solid #4285F4;
    color: black;
    border-radius: 24px;
    font-weight: 400;
  }
  .abcRioButtonIcon {
    padding-left: 55px !important;
    padding-top: 12px !important;
    float: left;
    width: 25px;
  }
  span.abcRioButtonContents {
    line-height: 44px !important;
    float: left !important;
    margin-left: 20px;
  }
}

@media only screen and (min-width: 601px) {
  .m-text-right {
    text-align: right;
  }
}

@media only screen and (min-width: 1121px) {
  .discover-search input[type=text] {
    height: 39px;
  }
}

@media only screen and (max-width: 1120px) {
  .uprise-banner {
    ul li {
      a {
        font-size: $small-text;
        padding: 0 10px;
      }
    }
  }
  nav {
    height: 94px;
    line-height: 58px;
  }
  .discover-search {
    height: 34px;
    position: absolute;
    right: -5px;
    bottom: 5px;
    input[type=text] {
      margin: 0;
      vertical-align: top;
      height: 32px;
    }
    button {
      top: 0;
      height: 32px;
      i {
        position: static;
        margin-top: -15px;
        vertical-align: middle;
        line-height: 1em;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .slanted-edge.slanted-edge-bottom {
    height: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .info-block {
    margin-left: -1.1em;
    margin-right: -1.1em;
  }
  .uprise-logo {
    display: none;
  }
  .uprise-logo.mobile {
    display: inline-block;
  }

  .card {
    .card-header {
      padding-left: $card-padding-mobile;
      padding-right: $card-padding-mobile;
    }
    .card-content {
      padding: $card-padding-mobile;
    }
  }
  hr {
    margin: 1em 0;
  }
  h1.super-large {
    font-size: 3em;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.7em;
  }
  h2.page-title {
    margin: 0.8em 0;
  }
  h2.page-title + .title-description {
    margin-top: -1em;
  }
  h3 {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 600px) {
  a.large-square-button {
    min-height: 0 !important;
    margin-bottom: 1em;
  }
  .col:last-child .large-square-button {
    margin: 0;
  }
}

@media only screen and (max-width: 585px) {
  .discover-search {
    bottom: auto;
    top: 9px;
    right: auto;
    left: 65px;
  }
  nav {
    height: 84px;
    .spacer-for-mobile {
      width: 230px;
      display: inline-block;
      height: 40px;
    }
    .logos {
      margin-right: 180px;
      display: inline-block;
    }
    ul.nav-links {
      position: absolute;
      top: 7px;
      left: 0;
      right: 0;
      li {
        line-height: normal;
        font-size: 0px;
        a {
          font-size: 14px;
          height: 36px;
          line-height: 36px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .card.user-profile-body .card-header .edit-btn {
    top: 12px;
  }
  .card.user-profile-body h3 img {
    margin-top: 2px;
  }
}

@media only screen and (max-width: 435px) {
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: block;
  }
  .discover-search {
    display: none;
  }
  nav .spacer-for-mobile {
    width: 50px;
  }
}