.static-page-copy {
    h2 {
        font-size: 24px;
        margin-top: 50px;
    }
    ul:not(.browser-default) li {
        margin-left: 15px;
        margin-bottom: 15px;
        list-style-type: disc;
        ul {
            margin-left: 15px;
            margin-top: 15px;
        }
    }
}

.static-page-container {
    padding: 50px 0;
}
