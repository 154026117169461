
input.item-search {
  margin: 10px 0 20px -0.75rem;
  border-radius: 16px;
  border: solid 1px #919395;
  width: 280px !important;
  height: 32px;
  font-size: 14px;
  color: #231f20;
  background: url(/static/img/magnifier-grey.svg) no-repeat scroll 95% 5px;
  padding-right: 40px;

}

div.wide-container {
  width: 90%;
  margin: auto;
}

pre {
  font-family: 'Open Sans', sans-serif;
}

input.item-search:-moz-placeholder {
  color: #919395;
}

input.item-search:-ms-input-placeholder {
  color: #919395;
}

input.item-search::-webkit-input-placeholder {
  color: #919395;
}

input.item-search:focus {
  border-bottom: 1px solid #747678 !important;
  box-shadow: none !important;
}

input.item-search::placeholder {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 6.64;
  letter-spacing: normal;
  text-align: left;
  color: #919395;
}

.item-search:-ms-input-placeholder {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 6.64;
  letter-spacing: normal;
  text-align: left;
  color: #919395;
}

.item-search:-moz-placeholder {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 6.64;
  letter-spacing: normal;
  text-align: left;
  color: #919395;
}

.item-search::-moz-placeholder {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 6.64;
  letter-spacing: normal;
  text-align: left;
  color: #919395;
}

.kyc-background-green {
  background-color: #34994c;
  border: 1px solid #EBEBEB;
  min-width: 30px;
  max-width: 30px;
}

.kyc-background-red {
  background-color: #992c2a;
  border: 1px solid #EBEBEB;
  min-width: 30px;
  max-width: 30px;
}

.kyc-green {
  color: #34994c;
  font-weight: bold;
  font-size: 15px;
}

.kyc-red {
  color: #992c2a;
  font-weight: bold;
  font-size: 15px;
}

.user-details {

  h5 {
    margin: 22px 0 5px 0;
    font-size: 19px;
    font-weight: 500;
    color: #4A4A4A;

    span.kyc-yes {
      color: #2a6d36;
      font-weight: bold;
      font-size: 20px;
    }

    span.kyc-no {
      color: #992c2a;
      font-weight: bold;
      font-size: 20px;
    }

  }

  p {
    margin: 0;
    padding: 0;
  }

  div.kyc-modal {
    height: 300px;

    h4 {
      font-size: 25px;
      color: #676767;
    }

    input[type=text] {
      max-width: 300px;
    }

    label {
      font-size: 16px;
    }

    div.col {
      padding: 10px;
      color: #333333;
    }
  }

}

div.pitch-admin {

  h1 {
    color: #5a5a5a;
    font-size: 28px;
    margin: 10px 0;
    text-align: center;
  }

  table {
    color: #444444;
    font-size: 14px;
  }

  textarea {
    border: 1px solid #9a9a9a;
    border-radius: 3px;
    padding: 5px 0 15px 5px;
    width: 100%;
    min-height: 200px;
  }

  div.select-wrapper {
    max-width: 400px;

    .number-select {
      width: 80px;
    }

    span.caret {
      color: #888888;
      right: 10px;
    }

    input.select-dropdown {
      border: 1px solid #9a9a9a;
      padding-left: 10px;
      color: #3b3b3b;
    }
  }

  div.team-member-avatar {
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 80px;
    height: 80px;
    margin: auto;

  }

  .input-field label.active {
    font-size: 16px;
    color: #333333;
  }

  [type="checkbox"]:checked + label:before {
    border-right: 2px solid $primary-color;
    border-bottom: 2px solid $primary-color;
  }

  .pitch-admin-button {
    width: 220px;
    font-size: 13px;

    .selected-button {
      background-color: #614f84 !important;
    }

  }

  .btn.pitch-admin-button.selected-button {
    background-color: #614f84 !important;
  }

  div.investment-option-parent{
    margin-top:15px;
  }

  div.profile-pic-avatar {
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 200px;
    height: 200px;
    margin: auto;

  }

  hr {
    border: 3px solid #EBEBEB;
    margin: 25px 0;
  }

  h4.title {
    font-size: 25px;
    margin: 20px 0 30px 0;
    color: #4c4c4c;
  }

  .submit-button {
    border-radius: 28px;
    background-color: #37274C;
    margin-top: 15px;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 11px;
    text-align: center;
    padding: 15px 20px;
    min-width: 200px;
  }

  div.grey-background {
    background-color: #EBEBEB;
    padding: 15px;
    margin: 20px 0;
    border-radius: 3px;
  }

  div.submit-parent {
    margin-top: 30px;
  }

  p.suggestion {
    margin-top: 10px;
    color: #5d5d5d;
    font-size: 13px;
    font-style: italic;
  }

  div.col.pitch-admin {
    padding: 20px 0;
  }

  label.investment-options {
    font-size: 12px;
    .active {
    }

  }

  div.row.admin-buttons-parent {
    div.col {
      padding: 3px 0;
    }
    div.col.last-button{
      margin-top:30px;

      a{
        background-color: #a3843b;
      }
    }
  }

  div.row.details-parent {
    div.col {
      padding: 5px;
    }

  }

}

.admin-spinner {
  position: absolute;
  left: 240px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.pitch_payments_list_row {
  font-size: 13px;
  color: #464646;
  cursor: pointer;
}

.pitch_payments_list_header {
  font-size: 13px;
  color: #313131;
  font-weight: bold;
}

.payment_status_initiated {
  background-color: #f4f4f4;
  color: black;
}

.payment_status_failed {
  background-color: #eacaca;
  color: black;
}

.payment_status_successful {
  background-color: #e6fff1;
  color: black;
}

.payment_status_refunded {
  background-color: #b1b1b1;
  color: black;
}

.payment_status_awaiting_payment {
  background-color: #ffe9d2;
  color: black;
}

#payments_table td {
  padding: 6px;
  margin: 4px;
}

.pitch_details_card_title {
  font-size: 20px !important;
}

.pitch_details_card_details {
  font-size: 15px !important;
}

.payment_details_list_parent {
  p {
    font-size: 13px !important;
  }

  h5 {
    font-size: 18px;
    color: #313131;
    font-weight: bold;
  }
}

