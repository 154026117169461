@import "variables";
@import "mixins";

a.login-show-password {
    color: $primary-color;
    font-size: $small-text;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 38px;
    cursor: pointer;
}

.auth-footer-url {
    text-align: center;
    margin-top: 1.4em;
    * {
        text-align: center;
    }
}

.investor-type-form {
    .row {
        form {
            h3 {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
                line-height: 11px;
                text-align: center;
                margin: 15px 0 25px;
            }
            .row {
                margin-top: 10px;
                margin-bottom: 0;
                padding-right: 10px;
                div {
                    text-align: center;
                    [type="radio"] + label:before, [type="radio"] + label:after {
                        border: 2px solid #4F2683;
                    }
                    [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:after {
                        background-color: #4F2683;
                    }
                    label {
                        color: #000000;
                        font-size: 16px;
                    }
                }
            }
        }
    }

}

.entrepreneur-message {
    margin-top: 1.3em;
}
.set-user-investor-form {
    .investor-types {

    }
    .investor-category-item {
        margin-top: .5em;
        position: relative;
        cursor: pointer;
        text-align: center;
        .investor-type-image {
            margin: -1em auto 0.2em auto;
            img {
                max-width: 80%;
            }
        }
        p {
            text-align: center;
            padding: 0 1em;
            line-height: 1.3em;
            color: #9A9A9A;
        }
        p.selected {
            color: $primary-color;
        }
        a {
            margin: 1.2em 0 1em 0;
            display: block;
        }
        &:after {
            @include border-radius(4px);
            border: 1px solid $med-grey-color;
            content: "";
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            left: 10px;
        }
        [type="radio"] + label {
            opacity: 0;
        }
        [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:after {
            background-color: #F3F3F3;
        }
    }
}

.set-user-investor-form.expand-investor-form {
    height: 100%;
    -webkit-transition: height 0.8s; /* Safari */
    transition: height 0.8s;
}

.signin,
.signup {
    .error {
        margin-top: -1em;
        margin-bottom: 1em;
    }
}
@media only screen and (max-width: 600px) {
    .select-type-radio-group {
        text-align: left;
    }
}

@media only screen and (max-width: 480px) {
    .set-user-investor-form .investor-types {
        margin-left: -1.2em;
        margin-right: -1.2em;
        .investor-category-item {
            .caption {
                font-size: 0.8em;
            }
            p {
                padding: 0;
            }
            &:after {
                left: 5px;
                right: 5px;
            }
        }
    }
    .clause {
        font-size: $small-text;
    }
}


