@import "variables";
@import "mixins";

.featured-pitches {
    margin-top: 10px;
    -webkit-animation: fadein 0.8s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.8s; /* Firefox < 16 */
    -ms-animation: fadein 0.8s; /* Internet Explorer */
    -o-animation: fadein 0.8s; /* Opera < 12.1 */
    animation: fadein 0.8s;
}

.pitch-home-container {
    overflow: visible;
    .card-content {
        height: 175px;
    }
    .pitch-logo {
        box-sizing: border-box;
        height: 128px;
        width: 128px;
        border: 0.5px solid rgba(219, 219, 219, 0.48);
        border-radius: 50%;
        background-color: #FFFFFF;
        box-shadow: 0 0 2px 0 rgba(55, 55, 55, 0.51);
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 10;
        background-size: cover;
        background-repeat: no-repeat;
    }
    p.pitch-title {
        font-size: 23px;
        font-weight: 600;
        padding-bottom: 10px;
    }
    p.pitch-body {
        color: #000000;
        font-size: 16px;
        .read-more {
            font-weight: bold;
            font-size: 15px;
        }
    }
    .home-card-action {
        .home-money-raised {
            color: $primary-color;
            font-size: 16px;
            font-weight: 600;
        }
        .pitch-target {
            color: #474747;
            font-size: 16px;
            font-weight: 600;
        }
        .pitch-province {
            color: #979797;
            font-size: 16px;
            line-height: 9.5px;
        }
        .pitch-days-left {
            color: #000000;
            font-size: 14px;
            span {
                font-weight: 600;
            }
        }
        .progress.raised-progress.home-raised {
            height: 9px;
        }
        .progress {
            position: relative;
            height: 4px;
            display: block;
            width: 100%;
            background-color: #E3E3E3;
            border-radius: 2px;
            margin: 0.5rem 0 1rem 0;
            overflow: hidden;
            .raised-progress {
                margin-left: 0;
                height: 22px;
                border-radius: 20px;
            }
            .home-progress {
                margin: 0.3rem 0 0.3rem 0;
            }
            .determinate {
                background-color: $primary-color;
            }
        }
    }
}

.pitch-home-container:hover {
    @include box-shadow-custom(0 3px 10px 0 rgba(0, 0, 0, 0.3));
}

.pitch-image-container {
    height: 200px;
    background-size: cover;
    overflow: hidden;
    background-color: #EBEBEB;
    background-position: center;
    img {
        width: 100%;
        height: auto;
    }
}

.pitch-header-text {
    margin-bottom: 10px;
}

.home {
    .partners-and-team {
        text-align: center;
        background-color: $primary-color;
        .container {
            position: relative;
            padding: 15px 0;
        }
        .card {
            position: relative;
            top: -45px;
            width: 100%;
            .card-content {
                padding-bottom: 0;

                a {
                    text-decoration: none;
                }

            }
            .card-action {
                background-color: #F7F7F7;
                padding: 18px;
                a {
                    color: $primary-color;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 11px;
                }
            }
        }
        .logos {
            margin-top: 2em;
            a, a:hover {
                color: #696969;
            }
            .group {
                margin-bottom: 30px;
            }
            img {
                max-width: 100%;
                //height: 70px;
                margin-bottom: 10px;
            }
            span {
                display: inline-block;
            }
            &.row {
                margin-bottom: 0;
            }
        }
    }
    .hero {
        background: left bottom -390px url("/static/img/background-resized.jpg") no-repeat;
        background-size: cover;
        text-align: center;
        padding: 4%;
        h3 {
            margin-bottom: 8.5em;
        }
        .buttons-group {
            margin-bottom: 17%;
            .btn {
                margin: 0 0.2em;
            }
        }
    }

    .user-call-to-action-bar {
        background-color: #fff;
        position: relative;
        padding-top: 45px;
        .group {
            padding-left: 5%;
            padding-right: 5%;
            a.btn {
                max-width: 300px;
                margin: 0 auto;
            }
        }
        h2 {
            text-align: center;
        }
        p {
            font-size: 16px;
            text-align: center;
        }
    }
    .pitch-section {
        background-color: #F2F2F2;
        padding: 4em 0 1em 0;
        .row {
            margin: 55px 0;
        }
        h1 {
            color: #30243E;
            font-size: 32px;
            text-align: center;
            margin-bottom: 15px;
        }
        h2 {
            color: #000000;
            font-size: 16px;
            text-align: center;
            margin-bottom: 25px;
        }
    }

    .newsletter-and-warning {
        background-color: $primary-color;
        position: relative;
        padding-bottom: 1em;
        .card {
            max-width: 450px;
            margin: 0 auto;
            top: -30px;
            .card-content {
                padding: 55px 20px 0;
                .oval-logo {
                    @include border-radius(50%);
                    @include box-shadow-custom(0 0 2px 0 rgba(55, 55, 55, 0.51));
                    top: -40px;
                }
                h2 {
                    color: #000000;
                    font-size: 24px;
                    font-weight: 600;
                    text-align: center;
                }
                p.small-text {
                    text-align: center;
                    color: #000;
                }
                hr {
                    border-width: 1px 0 0 0;
                    border-style: solid;
                    border-color: #D8D8D8;
                    width: 100%;
                    margin-top: 25px;
                }
            }
        }
        .card-action {
            border-top: 0;
            padding: 0 20px 1px 20px;
            .input-field {
                p {
                    opacity: 0.9;
                    color: #000000;
                    font-size: 14px;
                    text-align: left;
                    margin: 0;
                }
                .small-text {
                    font-size: 0.75em;
                    line-height: 1.4em;
                    margin-top: 0.5em;
                }
                .subscribe-btn {
                    margin-top: 22px;
                    width: 100%;
                }
            }
        }
    }
}

.risk-warning-container {
    color: white;
    text-align: center;
    background-color: #4F2683;
    padding: 20px 0;
    .container {
        h3 {
            margin-top: 10px;
        }
        a {
            color: white;
            margin-top: 2em;
            display: block;
        }
    }
}


.discover-search {
    position: relative;
    input[type=text] {
        width: 80%;
        color: #000;
    }
    button {
        position: absolute;
        top: 13px;
        right: 13px;
        width: 32px;
        height: 39px;
        padding: 0;
        background: #4F2683;
        border: 0;
        border-radius: 0 5px 5px 0;
        i {
            position: absolute;
            top: -15px;
            height: 32px;
            right: 8px;
        }
    }
}

textarea.contact-us {

    border: 1px solid #9a9a9a;
    border-radius: 3px;
    height: 100px;
    padding: 5px 0 15px 5px;
    background-color: #F8F8F8;

}

div.modal.modal-fixed-footer.subscription-modal {
    height: 200px;
    max-width: 400px;

    h4 {
        font-size: 20px;
        color: #676767;
        text-align: center;
    }
}

.bg-overlay {
    background-color: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
}

.pitch-equity-available {
    font-size: 14px;
}

.long-blurb.pitch-pre-wrapper.card-pre-wrapper {
    color: #000000;
    font-size: 14px;
    margin-top: 0;
    span {
        font-weight: bold;
    }
}

@media only screen and (min-width: 993px) {
    .container.partner-parent-container {
        width: 75%;
    }
}

@media only screen and (min-width: 993px) {
    .home .user-call-to-action-bar .group {
        min-height: 270px;
        a.btn {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

@media only screen and (min-width: 1250px) {
    .home .user-call-to-action-bar .group {
        min-height: 235px;
    }
}

@media only screen and (min-width: 1750px) {
    .home .user-call-to-action-bar .group {
        min-height: 220px;
    }
}

@media only screen and (max-width: 992px) {
    .home .user-call-to-action-bar .col:last-of-type {
        .group .oval-logo {
            margin-top: 30px;
            position: relative;
            top: 0;
        }
    }
}

@media only screen and (max-width: 1600px) {
    .home {
        .hero {
            background-position: left bottom -280px;
        }
    }
}

@media only screen and (max-width: 1540px) {
    .pitch-home-container .card-content {
        height: 220px;
    }
}

@media only screen and (max-width: 1400px) {
    .home {
        .hero {
            background-position: left bottom -270px;
        }
    }
}

@media only screen and (max-width: 1350px) {
    .home {
        .hero {
            background-position: left bottom -190px;
        }
    }
}

@media only screen and (max-width: 1260px) {
    .pitch-home-container .card-content {
        height: 260px;
    }
}

@media only screen and (max-width: 1200px) {
    .home {
        .hero {
            background-position: left bottom 0;
        }
    }
}

@media only screen and (max-width: 1102px) {
    .pitch-home-container .card-content {
        height: 300px;
    }
}

@media only screen and (max-width: 940px) {
    .pitch-home-container .card-content {
        height: 220px;
    }
}

@media only screen and (max-width: 768px) {
    .home .hero {
        background-size: 120%;
        background-position: center;
        .container {
            max-width: none;
            width: 100%;
        }
        .buttons-group {
            margin-bottom: 25%;
            .btn {
                padding: 0 1.2rem;
                font-size: $small-text;
                line-height: 40px;
                height: 41px;
                margin: 0 0.1em 0.5em 0;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        h3 {
            margin-bottom: 1em;
        }
    }
}

@media only screen and (max-width: 655px) {
    .pitch-home-container .card-content {
        height: 250px;
    }
}

@media only screen and (max-width: 631px) {
    .home .hero {
        background-size: 160%;
    }
}

@media only screen and (max-width: 600px) {
    .pitch-home-container .card-content {
        height: 180px;
    }
}

@media only screen and (max-width: 450px) {
    .home .hero {
        position: relative;
        z-index: 0;
        .container {
            z-index: 100;
            position: relative;
            h1 {
                font-size: 1.5em;
                color: #fff;
            }
            h3 {
                font-size: 1em;
                color: #fff;
                margin-top: 5px;
            }
        }
        .buttons-group {
            margin-top: 10px;
            margin-bottom: 10%;
            .btn {
                width: 100%;
            }
        }
    }
    .bg-overlay {
        display: block;
    }
}

@media only screen and (max-width: 422px) {
    .home .hero {
        background-size: 230%;
    }
}

@media only screen and (max-width: 360px) {
    .pitch-home-container .card-content {
        height: 220px;
    }
    .home .hero {
        background-size: 280%;
        background-position-y: 0;
    }
}

@media only screen and (max-width: 345px) {
    .home .hero {
        background-size: 280%;
        background-position-y: 0;
    }
}