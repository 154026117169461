$body-text-color: #30243E;
$site-background-colour: #FAFAFA;
$primary-color: #4F2683;
$primary-color-dark: #3C166B;
$light-grey-color: #eff2f4;
$med-grey-color: #D8D8D8;
$card-header-background-color: #EFEFEF;
$card-header-dark-background-color: #37274C;
$card-header-border-color: #CBC5D2;
$info-box-background-colour: #EFEEF2;
$success-color: #039E5B;
$error-color: #FF1030;

$pitch-pending-color: #F8D640;
$pitch-pending-dark-color: #D8BB3B;
$pitch-saved-color: #30243E;
$pitch-saved-dark-color: #000000;
$pitch-live-color: #3BA879;
$pitch-live-dark-color: #2D8961;
$pitch-finished-color: #F7F7F7;
$pitch-finished-dark-color: #CECECE;

$fixed-summary-footer-height: 50px;



$small-text: 0.85em;
$card-padding: 1.5em;
$card-padding-mobile: 1em;
$smallish-card-width: 500px;
$small-card-width: 400px;



/* table styling: */
$table-border-color: #979797;
$table-header-color: #EFEFEF;
$table-row-border-color: #E7E7E7;