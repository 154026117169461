@import "variables";

@mixin border-radius($radius) {
    webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow-custom($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin tile-box-shadow {
    -webkit-box-shadow: 0 1px 5px 0 rgba(0,0,0,.3);
    -moz-box-shadow: 0 1px 5px 0 rgba(0,0,0,.3);
    -ms-box-shadow: 0 1px 5px 0 rgba(0,0,0,.3);
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.3);
}

@mixin transition($sec) {
    -webkit-transition: all ease ($sec);
    -moz-transition: all ease ($sec);
    -ms-transition: all ease ($sec);
    transition: all ease ($sec);
}

@mixin rotate($deg) {
    -ms-transform: rotate($deg);
    -webkit-transform: rotate($deg);
    transform: rotate($deg);
    -moz-transform: rotate($deg);
}

@mixin opacity($opacity) {
    -moz-opacity: ($opacity);
    -khtml-opacity: ($opacity);
    opacity: ($opacity);
}

@mixin centralise() {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

@mixin vert-gradient($fallback, $bottom, $top) {
    background: $fallback;
    background: -moz-linear-gradient(top, $bottom 0%, $top);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $bottom), color-stop(100%, $top));
    background: -webkit-linear-gradient(top, $bottom 0%, $top 100%);
    background: -o-linear-gradient(top, $bottom 0%, $top 100%);
    background: -ms-linear-gradient(top, $bottom 0%, $top 100%);
    background: linear-gradient(to bottom, $bottom 0%, $top 100%);
}

@mixin faded-info-gradient {
    background: transparent;
    background: -moz-linear-gradient(top, rgba(239,238,242,0) 0%, rgba(239,238,242,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(239,238,242,0)), color-stop(100%, rgba(239,238,242,1)));
    background: -webkit-linear-gradient(top, rgba(239,238,242,0) 0%, rgba(239,238,242,1) 100%);
    background: -o-linear-gradient(top, rgba(239,238,242,0) 0%, rgba(239,238,242,1) 100%);
    background: -ms-linear-gradient(top, rgba(239,238,242,0) 0%, rgba(239,238,242,1) 100%);
    background: linear-gradient(to bottom, rgba(239,238,242,0) 0%, rgba(239,238,242,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f1f1', endColorstr='#f1f1f1', GradientType=0 );
}

@mixin resend-email-btn {
    margin-bottom: 15px;
    padding: 5px 0;
    display: inline-block;
    clear: both;
}
