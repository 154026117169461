@import "variables";
@import "mixins";

.discover-header {
    text-align: center;
    margin: 1.2em 0;
    font-size: 2em;
}

.discover-pitches {
    margin-top: 65px;
}