@import "variables";
@import "mixins";

.user-profile-page {
    .gradient {
        @include vert-gradient(#551C6C, #7A3472, #481C78);
        position: absolute;
        top: 0;
        height: 370px;
        width: 100%;
        z-index: 0;
        left: 0;
    }
}

.card .card-content p.forgot-password {
    color: #582C83;
    font-size: 16px;
    font-weight: bold;
    padding-left: 11px;
    cursor: pointer;
}

span.not-verified {
    font-size: $small-text;
    color: $error-color;
}

.delete-pitch-modal.modal-fixed-footer,
.delete-pitch-modal {
    width: 350px;
    height: 230px;
    overflow: hidden;
    .btn {
        margin: 3px 0 0 0;
    }
    .cta.cancel {
        margin: 12px 25px 0 0;
    }
}

a.delete-pitch {
    cursor: pointer;
    font-size: $small-text;
    padding: 5px 8px;
    margin: 20px 0 0 0;
    display: inline-block;
    img {
        vertical-align: middle;
        margin: -3px 7px 0 0;
    }
    &:hover {
        text-decoration: none !important;
    }
}

.card.user-profile-body {
    margin-top: 1.3em;
    h3 {
        margin: 0;
        font-weight: bold;
        img {
            width: 18px;
            height: auto;
            vertical-align: middle;
            margin: 5px 0.4em 0 -10px;
        }
    }
    .card-content {
        padding: 1em $card-padding .8em $card-padding;
    }
    p {
        margin-bottom: 0.5em;
    }
    button.change-password-btn {
        @include border-radius(4px);
        @include transition(0.4s);
        border: 1px solid $primary-color;
        background-color: white;
        color: $primary-color;
        font-weight: bold;
        padding: 0.2em 0.7em 0.3em 0.7em;
        margin-top: 1.2em;
        img {
            vertical-align: middle;
            margin: -3px 0.3em 0 0;
        }
        &:hover {
            @include tile-box-shadow;
        }
        &.check-email {
            color: $success-color;
            border-color: $success-color;
            cursor: auto;
            @include box-shadow-custom(none);
            img {
                margin-top: -2px;
            }
        }
    }
    a.resend-email-btn,
    .email-sent {
        @include resend-email-btn;
    }
    .email-sent {
        color: $success-color;
        font-weight: 600;
    }
    input {
        margin: -0.5em 0 1em 0;
    }
}

.user-profile-header {
    background-color: #EFEFEF;
    height: 46px;
    color: #30243E;
    font-size: 24px;
    font-weight: bold;
    line-height: 12px;
    padding: 16px 20px;
    border-radius: 2px 2px 0 0;
    .right {
        color: #582C83;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }
}

.user-profile-page {
    .large-square-button.new-item {
        min-height: 290px !important;
        margin-bottom: -4px;
        p {
            width: 100px;
        }
    }
}

.card.pitch-card {
    margin-top: 0;
    min-height: 245px;
    .card-image {
        @include border-radius(6px 6px 0 0);
        height: 82px;
        background: #88798C url("/static/img/pattern-repeat-white.svg") repeat;
    }
    .card-content {
        padding: 0.8em 1em 1em 1em;
        text-align: center;
        h4 {
            margin-bottom: 0.5em;
        }
    }
}

.pitches-block-create {
    box-sizing: border-box;
    height: 266px;
    width: 266px;
    margin: 25px 0 25px 25px;
    border: 1px dashed #4F2683;
    background-color: #F6F6F6;
    border-radius: 6px 6px;
    float: left;
    cursor: pointer;
    p {
        color: #582C83;
        font-size: 16px;
        font-weight: bold;
        line-height: 11px;
        text-align: center;
        margin-top: 135px;
    }
}

.investment-container {
    padding: 20px;
    .no-pitches-block {
        box-sizing: border-box;
        height: 200px;
        width: 366px;
        margin: 0 0 25px 25px;
        border: 1px dashed #4F2683;
        background-color: #F6F6F6;
        border-radius: 6px 6px;
        float: left;
        cursor: pointer;
        p {
            color: #582C83;
            font-size: 16px;
            font-weight: bold;
            line-height: 11px;
            text-align: center;
            margin-top: 135px;
        }
    }
    table {
        border-radius: 5px;
        border: 1px solid #979797;
        thead {
            tr {
                background-color: #EFEFEF;
            }
        }
    }
}

.preloader-wrapper.big.auth-spinner {
    width: 26px;
    height: 26px;
    margin-top: 7px;
    .spinner-layer {
        border-color: #fff;
    }
}

.create-pitch-card {
    margin-bottom: 1.4em;
}

@media only screen and (min-width: 601px) {
    .card.user-profile-body select {
        margin-top: -0.4em;
    }
}

@media only screen and (max-width: 600px) {
    .user-profile-page .large-square-button.new-item {
        min-height: 150px !important;
        margin-bottom: 20px;
    }
}