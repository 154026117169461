@import "variables";
@import "mixins";

.pitch-page {
    margin-top: 2em;
    .card.main-card {
        .card-header {
            background-color: transparent;
            padding-bottom: 0;
            border: 0;
        }
        .card-content {
            iframe {
                @include border-radius(6px);
                width: 95%;
            }
            .blurb {
                margin-top: 1em;
            }
        }
    }
    .card {
        &#introduction {
            margin-top: 0;
        }
        .card-content {
            .banner-img {
                max-height: 500px;
                overflow: hidden;
                margin-bottom: 1.5em;
                img {
                    max-width: 100%;
                }
            }
            p.long-blurb {
                white-space: pre-line;
                margin-bottom: 1em;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            .social-media a {
                display: inline-block;
                margin: 1em 1em 1em 0;
                text-decoration: none;
                text-align: center;
                .caption {
                    font-size: 0.8em;
                }
                img {
                    width: 45px;
                    margin: 0 auto;
                    height: auto;
                }
            }
            .press {
                margin-bottom: 1em;
            }
        }
    }
    &.container {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    &.container .no-margin {
        margin: 0;
    }
    .pitch-details-right-container {
        @include border-radius(6px);
        background-color: #F2F2F2;
        padding: 1em 1em 0.5em 1em;
        width: 100%;
        .pledged-wrapper {
            width: 115%;
            margin: 0 0 20px -27px;
            float: left;
            position: relative;
            .pledged-container {
                @include border-radius(5px);
                padding: 0.6em 0.5em 0.5em 0.5em;
                border: 1px solid #979797;
                background-color: white;
                line-height: 12px;
                .pitch-details-text {
                    color: #4F2683;
                    font-size: 38px;
                    font-weight: bold;
                    line-height: 26px;
                }
            }
        }
        .pitch-tipping-point {
            position: absolute;
            bottom: 2px;
            font-size: 0.8em;
            margin-left: 12px;
        }

        .pitch-tipping-point.arrow-adjustment {
            margin-left: -12px;
        }

        .pitch-tipping-point.arrow-adjustment-93 {
            margin-left: -8px;
        }

        .pitch-tipping-point.arrow-adjustment-50 {
            margin-left: 0;
        }
        .pitch-tipping-point.fix-progress-left {
            left: 90%;
        }
        .pitch-tipping-point:before {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            content: "";
            border-top: 5px solid #004d40;
            bottom: 0;
            position: absolute;
        }
        .pitch-tipping-point:after, .pitch-tipping-point:before {
            top: 1px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        .pitch-tipping-point:after {
            border-top-color: #fff;
            border-width: 7px;
            margin-left: -7px;
        }
        .pitch-tipping-point:before {
            border-color: rgba(194, 225, 245, 0);
            border-top-color: #979797;
            border-width: 8px;
            margin-left: -8px;
        }
        .money-raised-bar {
            height: 32px;
            border-radius: 20px;
            position: relative;
            display: block;
            width: 100%;
            background-color: #fff;
            margin: 0.5rem 0 1rem 0;
            overflow: hidden;
            .determinate {
                background-color: #4F2683;
                height: 45px;
                .funds-raised {
                    white-space: nowrap;
                    padding-left: 4px;
                    padding-right: 4px;
                    padding-top: 4px;
                    font-size: 16px;
                    color: #d3d3d3;
                    margin: 0;
                }
            }
        }
        p.pitch-fine-text {
            color: #000000;
            font-size: 16px;
            font-weight: bold;
            line-height: 11px;
            padding: 0;
            margin: 0 0 14px 0;
        }
        .pitch-insight-values {
            p {
                color: #30243E;
                font-size: 29px;
                margin: 2px 0;
                span {
                    color: #000000;
                    font-size: 16px;
                }
            }
        }
    }
}

pre.pitch-pre-wrapper {
    white-space: pre-wrap;
    overflow: hidden;
    ul {
        display: inline-table;
        margin: 0;
        li {
            margin-bottom: 10px;
            margin-left: 35px;
            list-style-type: disc;
        }
    }
    h3 {
        margin: 0;
    }
}

.pitch-docs-container {
    @include border-radius(4px);
    padding: 0.2em 0;
    background-color: $light-grey-color;
    .docs-item {
        margin-top: 5px;
        line-height: 1.4em;
        padding: 0.4em 0.7em;
        a.delete {
            float: left;
            margin-right: 0.8em;
        }
    }
}

span.payment-small-text {
    font-size: 14px;
}

.board-small-text {
    font-size: 12px;
    padding-left: 34px;
    font-weight: bold;
}

.team-profile-pic {
    height: 206px;
    width: 206px;
    background-position: center;
    background-size: cover;
    float: left;
    margin-right: 14px;
    border-radius: 50%;
}

.pitch-details-btn {
    width: 100%;
    margin: 18px 0;
}

.social-share-container {
    width: 215px;
    display: block;
    margin: 0 auto;
    a {
        padding: 0 15px;
        height: 35px;
        line-height: 32px;
        &:hover {
            padding: 0 15px;
            height: 35px;
            line-height: 32px;
        }
        &:focus {
            padding: 0 15px;
            height: 35px;
            line-height: 32px;
        }
    }
}

.pitch-details-row-container {
    .col.m9 {
        padding-right: 0;
    }
    .col.m3 {
        padding-left: 0;
    }
}

.pitch-menu {
    @include border-radius(4px 0 0 4px);
    @include tile-box-shadow;
    background-color: white;
    padding-bottom: 1px;
    ul {
        margin: 0;
        li {
            border-bottom: 1px solid #E7E7E7;
            a {
                color: #000000;
                font-size: 16px;
                padding: 10px 13px;
                display: block;
                text-decoration: none;
                border-left: solid 5px transparent;
            }
            a.active {
                background-color: #F2F2F2;
                border-left-color: #F5A623;
                width: 100%;
            }
        }
        li:first-of-type a.active {
            @include border-radius(4px 0 0 0);
        }
    }
    button.pitch-details-btn {
        width: 90%;
        display: block;
        margin: 20px auto;
    }

    #back-to-top {
        background-color: #FFF;
        border-radius: 20px;
        box-shadow: 0 1px 3px 0 rgba(55, 55, 55, 0.51);
        border: none;
        position: absolute;
        right: 0;
        bottom: -45px;
        padding: 2px 15px;
        span {
            color: #4F2683;
            font-weight: 600;
            font-size: 14px;
        }
    }
}

.pitch-menu.stick-pitch-nav {
    position: fixed;
    top: 20px;
}

input[type=text].ng-invalid-pattern {
    border-color: red;
}

div#notAvailable {
    width: 400px;
    .modal-content {
        p {
            a {
                color: #4F2683;
            }
        }
    }
}

.card {
    .card-content {
        p.international-investor {
            margin-bottom: 20px;
            a {
                color: #4F2683;
                font-weight: 600;
                text-decoration: none;
            }
        }
    }
}

.limited-blur {
    -webkit-filter: blur(5px); /* Safari */
    filter: blur(5px);
}

.sign-in-container-card {
    position: relative;
    .sign-in-to-see-more-card {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 35px auto;
        width: 247px;
        z-index: 100;
        padding: 20px;
        background-color: #fff;
        height: 150px;
        border-radius: 15px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        i {
            display: block;
            text-align: center;
            font-size: 50px;
        }
        h3 {
            margin: 0;
            text-align: center;
            font-size: 18px;
        }
        h4 {
            margin: 10px 0;
            text-align: center;
            font-size: 16px;
        }
    }

    .sign-in-to-see-more-menu {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 55px auto;
        width: 207px;
        z-index: 100;
        padding: 20px;
        background-color: #fff;
        height: 130px;
        border-radius: 15px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        i {
            display: block;
            text-align: center;
            font-size: 50px;
        }
        h3 {
            margin: 0;
            text-align: center;
            font-size: 16px;
        }
        h4 {
            margin: 10px 0;
            text-align: center;
            font-size: 14px;
        }
    }
}

form.create-pitch-form {
    label {
        color: #000;
    }
}

.trust-upload-container {
    margin-top: 40px;
}
.upload-container {
    margin-top: 30px;
}
.all-fields-required-message {
        margin: -1.5rem 0 2rem 0;
}

#container.quicklysign-container {
    width: 100%;
}

#container.document-signed {
    display: none!important;
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
    .pitch-page.container {
        width: 720px;
        padding-left: 0;
        padding-right: 0;
    }
    .pitch-menu.stick-pitch-nav {
        width: 169.5px;
    }

}

@media only screen and (min-width: 993px) {
    .pitch-page.container {
        width: 950px;
    }
    .pitch-menu.stick-pitch-nav {
        width: 216.63px;
    }
}

@media only screen and (min-width: 1200px) {
    .pitch-page.container {
        width: 1150px;
    }
    .pitch-menu.stick-pitch-nav {
        width: 266.25px;
    }
}

@media only screen and (min-width: 1500px) {
    .pitch-page.container {
        width: 1300px;
    }
    .pitch-menu.stick-pitch-nav {
        width: 298.75px;
    }
}

@media only screen and (max-width: 992px) {
    .pitch-page .card.main-card .card-content iframe {
        width: 100%;
    }
    .pitch-page {
        .pitch-details-right-container {
            margin-top: 2em;
            .pledged-wrapper {
                float: right;
                width: auto;
            }
            .pitch-insight-values > p {
                display: inline-block;
                margin-right: 1em;
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
        .pitch-details-btn {
            max-width: 300px;
            margin: 1.5em auto 0 auto;
            display: block;
        }
        .pitch-social {
            margin-top: 1em;
        }
    }
}

@media only screen and (max-width: 768px) {
    .pitch-menu.stick-pitch-nav {
        width: 133.25px;
    }
    .pitch-page {
        .pitch-menu {
            ul li a {
                font-size: $small-text;
                border-left-width: 3px;
                padding: 8px 10px;
                line-height: 1.3em;
            }
        }
    }
    .pitch-menu button.pitch-details-btn {
        margin: 13px auto;
    }
}

@media only screen and (max-width: 600px) {
    .pitch-menu,
    .pitch-menu.stick-pitch-nav {
        @include border-radius(0);
        position: fixed;
        top: auto;
        bottom: 0;
        z-index: 1001;
        left: 0;
        right: auto;
        width: 100%;
        padding: 0;
        text-align: center;
        button.pitch-details-btn {
            width: 98%;
            margin: 8px auto;
        }
        #back-to-top {
            display: none;
        }
        ul li {
            display: inline-block;
            border: 0;
            a {
                padding: 7px 10px 5px 10px;
                border: 0;
                border-bottom: 3px solid transparent;
                font-size: $small-text;
            }
            a.active {
                border-bottom-color: #F5A623;
            }
        }
    }
    .pitch-menu ul li:first-of-type a.active {
        @include border-radius(0);
    }
    .pitch-page {
        .card .card-content iframe {
            height: 250px;
        }
        .pitch-details-btn {
            max-width: none;
        }
        .pitch-details-right-container .pledged-wrapper {
            width: 100%;
        }
    }
}